// const heroku = "https://calm-falls-29246.herokuapp.com/";

const apiUrl =  "https://api.arc-studio.co/api/";
export default {
  get: async function (url, lang) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", apiUrl + url + "?lang=" + lang);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.responseType = "json";
      xhr.send();
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
    });
  },
  post: async function (url, lang, body) {
    return await new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", apiUrl + url + "?lang=" + lang, true);
      xhr.setRequestHeader("Accept", "application/json");

      try {
        xhr.send(body);
        xhr.onloadend = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.response));
            this.success = true;
          } else {
            reject(
              JSON.stringify({
                status: xhr.status,
                response: xhr.response,
              })
            );
            this.success = false;
          }
        };
      } catch (error) {
        throw new Error(error);
      }
    });
  },
};
