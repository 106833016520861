<template>
  <div
    class="
      home-slider-container
      h-[100vh]
      bg-black
      top-0
      left-0
      w-full
      z-[2]
      relative
      overflow-hidden
    "
  >
    <div
      class="
        w-[100vw]
        h-[120vh]
        absolute
        left-0
        top-0
        z-[-1]
        home-slide-img
        parallex-image-slide
      "
      v-for="(slide, index) in sliders"
      :key="index"
      :style="`background: url(${slide.image})`"
      :class="currentSlide == index ? 'slide-show' : 'slide-hide'"
    ></div>
    <div
      class="
        home-slide-img-overlay
        w-[100vw]
        h-[100vh]
        px-[3vw]
        md:px-[45px]
        lg:px-[90px]
      "
    >
      <div
        class="
          w-[4px]
          h-[4px]
          no-background-on-mobile
          bg-white
          absolute
          left-[3vw]
          md:left-[43px]
          lg:left-[88px]
          bottom-[45px]
          lg:bottom-[88px]
        "
      ></div>
      <div
        class="
          w-[4px]
          h-[4px]
          no-background-on-mobile
          bg-white
          absolute
          right-[3vw]
          md:right-[43px]
          lg:right-[88px]
          bottom-[45px]
          lg:bottom-[88px]
        "
      ></div>
      <div
        class="
          w-[4px]
          h-[4px]
          top-[74px]
          lg:top-[88px]
          no-background-on-mobile
          bg-white
          absolute
          right-[3vw]
          md:right-[43px]
          lg:right-[88px]
        "
      ></div>
      <div class="scroll-downs hidden lg:flex flex-col items-center">
        <span
          class="
            uppercase
            pt-[1px]
            rotate-[-90deg]
            whitespace-nowrap
            mb-14
            text-white
          "
        >
          Scroll Down</span
        >
        <div class="mousey">
          <div class="scroller"></div>
        </div>
      </div>
      <div
        class="
          h-full
          border-x
          remove-border-on-mobile
          layout-border
          lg:pt-[90px]
           lg:pb-[90px] pt-[75px] pb-[30px]
          flex flex-col
          justify-end
          w-full
        "
      >
        <div>
          <div
            class="
              flex
              w-full
              justify-between
              lg:px-8
              md:px-2
              px-0
              uppercase
              text-white
              absolute
              slide-btns
            "
          >
            <div class="flex cursor-pointer prev-btn" @click="prevSlide">
              <img
                src="/static/images/icons/arrow-left.svg"
                class="w-[10px] arrow"
                alt="left"
              />
              <div class="uppercase title tracking-widest">Previous</div>
            </div>
            <div class="flex cursor-pointer next-btn" @click="nextSlide">
              <div class="uppercase title tracking-widest">Next</div>
              <img
                src="/static/images/icons/arrow-right.svg"
                class="w-[10px] arrow"
                alt="right"
              />
            </div>
          </div>
          <div
            class="
              flex
              w-full
              justify-end
              text-[30px]
              lg:text-[40px]
              short:text-2xl
              px-8
              uppercase
              slide-text-container
              text-white
            "
          >
            <div
              class="
                w-[300px]
                text-right
                tracking-wider
                short:tracking-normal
                leading-[50px]
                transition-all
                slide-text
              "
              v-for="(slide, index) in sliders"
              :key="index"
              :class="currentSlide == index ? 'text-show' : 'text-hide'"
              v-html="sliders[index]?.title"
            ></div>
          </div>
        </div>
        <div class="flex justify-between w-full">
          <div class="px-4 lg:px-8 sm:py-4 py-0 w-full">
            <div class="flex justify-between items-center w-full">
              <div class="text-slideGray font-bold text-[20px] text-roboto-14">
                <span class="text-[42px]">{{ currentSlide + 1 }}{{ " " }}</span>
                /
                {{ sliders.length }}
              </div>
              <div class="slide-btn-container">
                <ViewProjectBtn
                  v-for="(slide, index) in sliders"
                  :key="index"
                  title="view project"
                  :to="sliders[index].link"
                  :class="
                    currentSlide == index ? 'slide-btn-show' : 'slide-btn-hide'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          w-[100vw]
          border-t
          remove-border-on-mobile
          bottom-[3vw]
          md:bottom-[45px]
          lg:bottom-[90px]
          left-0
          layout-border
          absolute
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  props: ["sliders"],
  data() {
    return {
      currentSlide: 0,
    };
  },
  methods: {
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide = this.currentSlide - 1;
      }  else if (this.currentSlide == 0) {
        this.currentSlide = this.sliders.length - 1;
      }
    },
    nextSlide() {
      if (this.currentSlide < this.sliders.length - 1) {
        this.currentSlide = this.currentSlide + 1;
      } else if (this.currentSlide == this.sliders.length - 1) {
        this.currentSlide = 0;
      }
    },
  },
  mounted() {
    let parallexAnimation = gsap.to(".parallex-image-slide", {
      yPercent: -15,
    });
    ScrollTrigger.create({
      trigger: ".home-slider-container",
      start: "top center",

      animation: parallexAnimation,
      scrub: true,
    });
  },
};
</script>
