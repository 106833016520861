<template>
  <div
    class="
      home-projects
      h-full 
      bg-[#1D1D1D]
      border-t-0
      md:border-t
      layout-border
      relative
    "
  >
    <div
      class="
        border-x-0
        md:border-x
        mx-[3vw]
        md:mx-[45px]
        lg:mx-[90px]
        layout-border
        h-full
      "
    >
      <div class="justify-between text-white flex items-center h-[20vh]">
        <div class="grid grid-cols-3 w-full h-full">
          <div class="col-span-1 border-r-0 lg:border-r layout-border h-full">
            <div
              class="
                uppercase
                text-[24px]
                lg:text-[36px]
                flex
                items-center
                h-full
                layout-padding
              "
            >
              Projects
            </div>
          </div>
          <div
            class="lg:flex hidden col-span-1 border-r layout-border h-full"
          ></div>
          <div
            class="
              col-span-2
              lg:col-span-1
              flex
              items-center
              justify-end
              h-full
            "
          >
            <div
              class="
                flex
                w-[100px]
                lg:w-[270px]
                justify-between
                px-0
                lg:px-8
                uppercase
                text-white
              "
            >
              <div class="flex cursor-pointer prev-btn" id="prev-project-home">
                <img
                  src="/static/images/icons/arrow-left.svg"
                  class="w-[15px] lg:w-[10px] arrow"
                  alt="left"
                />
                <div class="title uppercase lg:flex hidden">Previous</div>
              </div>
              <div class="flex cursor-pointer next-btn" id="next-project-home">
                <div class="title uppercase lg:flex hidden">Next</div>
                <img
                  src="/static/images/icons/arrow-right.svg"
                  class="w-[15px] lg:w-[10px] arrow"
                  alt="right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-white projects-slider">
        <swiper
          :breakpoints="breakpoints"
          :navigation="navigation"
          :centeredSlides="true"
            :initialSlide = "2"
    >
          <swiper-slide v-for="project in projects.slice(0,6)" :key="project.id">
            <ProjectCard :project="project" />
          </swiper-slide>
           <swiper-slide >
            <div class="w-full border-y layout-border grid place-content-center h-[80vh]">
              <router-link to="/projects">
                <div class="font-bold lg:text-2xl text-xl cursor-pointer">More</div>
              </router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from "/src/components/UI/Cards/ProjectCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Navigation]);
export default {
  props: ["projects"],
  data() {
    return {
      navigation: {
        nextEl: "#next-project-home",
        prevEl: "#prev-project-home",
      },
      breakpoints: {
        // when window width is >= 320px

        // when window width is >= 480px
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        570: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        860: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        // when window width is >= 640px
        1050: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1500: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1800: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ProjectCard,
  },
};
</script>


<style scoped>
.swiper-container {
  overflow: visible;
}
</style>