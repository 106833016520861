<template>
  <div class="grid grid-cols-12 min-h-[100vh]">
    <div
      class="
        w-[4px]
        h-[4px]
        absolute
        right-[3vw]
        md:right-[43px]
        lg:right-[88px]
        top-[88px]
        bg-transparent
        lg:bg-white
      "
    ></div>
    <div
      class="
        col-span-12
        xl:col-span-5
        border-r-0
        md:border-r
        layout-border
        md:pl-[45px]
        lg:pl-[90px]
      "
    >
      <div
        class="
          border-l
          layout-border
          h-full
          layout-padding
          pt-[90px]
          w-full
          xl:w-11/12
          pb-[90px]
          xl:pb-0
        "
      >
        <div class="uppercase text-white text-[36px] mt-16 tracking-widest">
          Contact <br />
          <strong> US</strong>
        </div>
        <div class="text-white mt-8">
          <div class="font-bold">Email us at</div>
          <div
            class="mt-2"
            v-for="email in contact.data[0]['email']"
            :key="email"
          >
            {{ email.value }}
          </div>
        </div>
        <div class="text-white mt-8">
          <div class="font-bold">Call us at</div>
          <div class="mt-2 grid grid-cols-2">
            <div
              class="col-span-1 text-roboto"
              v-for="phone in contact.data[0]['phone']"
              :key="phone"
            >
              {{ phone.value }}
            </div>
          </div>
        </div>
        <div class="text-white mt-8">
          <div class="font-bold">Find us at</div>
          <div
            class="mt-2"
            v-for="address in contact.data[0]['address']"
            :key="address"
          >
            {{ address.value }}
          </div>
        </div>
        <div class="text-white mt-8">
          <div class="font-bold">Our Office Opening Hours</div>
          <div class="mt-2">
            {{ contact.data[0].opening_hours.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 xl:col-span-7">
      <div
        class="
          border-r-0
          md:border-r
          layout-border
          right-[3vw]
          md:right-[45px]
          lg:right-[90px]
          top-0
          absolute
          h-full
          z-[0]
        "
      ></div>
      <div class="map-container overflow-hidden mt-0 xl:mt-[90px]">
        <GoogleMap
          api-key="AIzaSyCcCmA6K21fsvsFqlG7UyYSSJN0MPRvmBc"
          :style="
            screenSize > 1280
              ? `width: 100%; height: calc(100vh - 50px)`
              : 'width: 100%; height: calc(50vh - 50px)'
          "
          :styles="style"
          :center="mapcenter"
          :zoom="14"
          :zoomControl="false"
          :streetViewControl="false"
          :fullscreenControl="false"
          :mapTypeControl="false"
          :keyboardShortcuts="false"
        >
          <Marker
            :options="{
              position: mapcenter,
              icon: '/static/images/icons/marker.png',
            }"
          />
        </GoogleMap>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  data() {
    return {
      mapcenter: { lat: 35.5558, lng: 45.4351 },
      zoomcontrol: 10,
      dir: null,
      activeCity: null,

      style: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#181818",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1b1b1b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2c2c2c",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8a8a8a",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#373737",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#3c3c3c",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [
            {
              color: "#4e4e4e",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d3d3d",
            },
          ],
        },
      ],
    };
  },
  computed: {
    contact() {
      return this.$store.getters["contacts/contacts"];
    },
    screenSize() {
      return window.innerWidth;
    },
  },
  watch: {
    contact() {
      let mapCenter = {
        lat: parseFloat(this.contact?.data[0]["map"][0]?.lat),
        lng: parseFloat(this.contact?.data[0]["map"][0]?.lng),
      };
      this.mapcenter = mapCenter;
    },
  },
  components: {
    GoogleMap,
    Marker,
  },
  mounted() {
    document.title = this.$route.meta.name;

    this.$store.dispatch("contacts/contacts");
  },
};
</script>