<template>
  <div class="text-page text-white layout-padding mt-[140px]">
    <div class="lg:text-4xl lg-text-resize text-2xl font-bold">Sitemap</div>
    <div class="grid grid-cols-2 text-white">
      <div class="col-span-1 py-6">
        <router-link to="/">
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            Home
          </div>
        </router-link>
        <router-link to="/about">
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            about
          </div>
        </router-link>
        <router-link to="/projects">
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            projects
          </div>
        </router-link>
        <router-link to="/contact">
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            contact
          </div>
        </router-link>
        <router-link to="/services">
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            services
          </div>
        </router-link>
        <router-link
          v-for="service in services"
          :key="service.id"
          :to="`/services?service=${service?.id}`"
        >
          <div
            class="
              flex
              items-center
              justify-between
              w-max-content
              cursor-pointer
              arrow-btn
              py-2
              mb-1
              main-menu-item main-menu-about-item
            "
          >
            {{ service.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
  },
  mounted() {
    document.title = this.$route.meta.name;
  },
};
</script>