import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Projects from "../views/Projects.vue";
import Contact from "../views/Contact.vue";
import ProjectDetails from "../views/ProjectDetails.vue";
import Services from "../views/Services.vue";
import NotFound from "../views/NotFound.vue";
import SiteMap from "../views/SiteMap.vue";
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { name: "Home" },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { name: "About" },
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    meta: { name: "Services" },
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    meta: { name: "Projects" },
  },
  {
    props: true,
    path: "/projects/:id",
    name: "projectDetails",
    component: ProjectDetails,
  },
  {
    path: "/contact",
    name: "contacts",
    component: Contact,
    meta: { name: "Contacts" },
  },
  {
    path: "/:notFound(.*)",
    component: NotFound,
    meta: { name: "Not Found" },
  },
  {
    path: "/sitemap",
    component: SiteMap,
    meta: { name: "Sitemap" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // @M: we need to configure this based on routes
    if (savedPosition) {
      return savedPosition;
    }
    if(!from.path.includes("projects")){
      store.dispatch("projects/setScrollPosition", 0);
    }
    return { top: 0, left: 0 };
  },
});

export default router;
