<template>
  <router-link :to="to" v-if="to">
    <div
      class="
        uppercase
        relative
        tracking-widest
        view-project-btn
        font-bold
        text-white
        bg-white bg-opacity-40
        px-[40px]
        py-[10px]
      "
    >
      {{ title }}
      <div
        class="
          view-project-btn-line
          w-[40px]
          border-t-2 border-white border-opacity-40
          absolute
          top-[21px]
          right-[-20px]
        "
      ></div></div
  ></router-link>
</template>

<script>
export default {
  props: ["title", "to"],
};
</script>