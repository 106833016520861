import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewProjectBtn from "/src/components/UI/Buttons/ViewProjectBtn.vue";
import ReadmoreBtn from "/src/components/UI/Buttons/ReadmoreBtn.vue";
import Modal from "/src/components/UI/Modal.vue";
import VueGtag from "vue-gtag"
import VueEasyLightbox from "vue-easy-lightbox";

let app = createApp(App);

app.use(VueGtag, {
    config: { id: "UA-152033585-1" }
  }, router)
app.component("Modal", Modal);
app.component("ViewProjectBtn", ViewProjectBtn);
app.component("ReadmoreBtn", ReadmoreBtn);
app.use(VueEasyLightbox);
app.use(store);
app.use(router);
app.mount("#app");
