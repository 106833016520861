import http from "../../../request";

export default {
  services: async (context) => {
    let response = await http.get("services", localStorage.getItem("lang"));
    context.commit("setServices", response.data);
  },
  serviceDetails: async (context, payload) => {
    let response = await http.get(
      `services/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setServiceDetails", response);
  },
};
