<template>
  <teleport to="body">
    <div
      class="
        footer-top
        bg-[#121212]
        text-white
        min-h-[200px]
        lg:min-h-[300px]
        px-[3vw]
        md:px-[45px]
        lg:px-[90px]
        flex flex-col
        justify-center
      "
    >
      <div class="w-[calc(100vw - 180px)] flex justify-between">
        <div class="w-full">
          <div class="flex items-center sm:items-end">
            <div
              class="footer-logo flex lg:hidden"
              style="background: url(/static/images/footer-logo.png)"
            ></div>
            <div>
              <div
                class="
                  text-[20px]
                  lg:text-[30px]
                  font-bold
                  mt-4
                  mb-1
                  tracking-widest
                "
              >
                Arc Design Studio
              </div>
              <div class="tracking-widest uppercase text-[10px]">
                TOWARD AN INNOVATIVE DESIGN
              </div>
            </div>
          </div>
          <div
            class="
              lg:flex
              hidden
              mt-12
              w-full
              lg:w-8/12
              font-bold
              tracking-widest
              justify-between
            "
          >
            <router-link to="/">
              <div class="uppercase text-xs cursor-pointer">
                Home
              </div></router-link
            >
            <router-link to="/about">
              <div class="uppercase text-xs cursor-pointer">
                About Us
              </div></router-link
            >
            <router-link to="/projects">
              <div class="uppercase text-xs cursor-pointer">
                Projects
              </div></router-link
            >
            <router-link to="/services">
              <div class="uppercase text-xs cursor-pointer">
                SERVICES
              </div></router-link
            >
            <router-link to="/contact">
              <div class="uppercase text-xs cursor-pointer">
                Contact
              </div></router-link
            ><router-link to="/sitemap">
              <div class="uppercase text-xs cursor-pointer">
                sitemap
              </div></router-link
            >
          </div>
        </div>
        <div
          class="footer-logo lg:flex hidden"
          style="background: url(/static/images/footer-logo.png)"
        ></div>
      </div>
    </div>
    <div
      class="
        footer-bottom
        bg-[#121212]
        text-white
        border-t
        layout-border
        items-center
        min-h-[150px]
        px-[3vw]
        md:px-[45px]
        lg:px-[90px]
        flex flex-wrap
        justify-center
        sm:justify-between
      "
    >
      <div class="flex font-base opacity-80">
        <a
          v-for="social in socials?.socials"
          :key="social.id"
          target="_blank"
          :href="social.link"
        >
          <i class="mr-4" :class="social.icon"></i>
        </a>
      </div>
      <div class="uppercase text-xs opacity-80">
        Copyright {{ new Date().getFullYear() }} - Arc Design Studio | Developed
        by
        <a href="https://blackace.tech" target="_blank" class="font-bold"
          >Black Ace</a
        >
      </div>
    </div></teleport
  >
</template>

<script>
export default {
  computed: {
    socials() {
      return this.$store.getters["socials/socials"];
    },
  },
};
</script>