<template>
  <div class="relative bg-[#1D1D1D] overflow-hidden c-service-bg">
    <div
      class="
        absolute
        transition-all
        lg:hidden
        flex flex-col
        duration-1000
        mobile-services
        top-[90px]
        w-[100vw]
        text-white
        h-full
        bg-[#1d1d1d]
        c-service-bg
      "
      :class="
        currentService == null && screenSize < 1024
          ? 'mobile-services-show'
          : 'mobile-services-hide'
      "
    >
      <div class="min-h-[20vh] grid grid-cols-3 text-white">
        <div class="col-span-1 border-r-0 lg:border-r layout-border">
          <div
            class="
              uppercase
              text-[24px]
              lg:text-[36px]
              layout-padding
              flex
              items-center
              h-full
            "
          >
            Services
          </div>
        </div>
        <div
          class="
            mt-[-90px]
            pt-[90px]
            col-span-1
            border-r-0
            lg:border-r
            layout-border
          "
        ></div>
      </div>
      <div
        class="
          w-full
          layout-border
          text-white
          lg:hidden
          flex flex-col
          layout-padding
          justify-start
          text-[20px]
          leading-10
        "
      >
        <div class="relative" v-for="service in services" :key="service.id">
          <div
            class="cursor-pointer duration-100 c-service-text transition-all"
            @click="selectedService = service.id"
            :style="service.id == 7 ? 'color: #3863AE' : ''"
          >
            {{ service?.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="
        currentService != null && screenSize < 1024
          ? 'mobile-service-details-show'
          : 'mobile-service-details-hide show-on-large'
      "
      class="
        pt-[90px]
        sm:pt-0
        min-h-[100vh]
        grid grid-cols-12
        md-[3vw] md:mx-[45px]
        lg:mx-[90px]
        relative
        transition-all
        duration-1000
      "
    >
      <div class="col-span-12 sm:col-span-6 lg:col-span-8 flex">
        <div class="grid grid-cols-12">
          <div
            class="
              col-span-6
              border-x
              c-service-border
              layout-border
              text-white
              hidden
              lg:flex
              flex-col
              px-12
              lg:justify-start
              justify-center
              xl:justify-center
              lg:pt-[200px]
              xl:pt-0
              text-[20px]
              leading-10
            "
          >
            <div class="relative" v-for="service in services" :key="service.id">
              <div
                :style="selectedService == 7 ? 'background: #3863AE' : ''"
                class="
                  w-0
                  h-[2px]
                  du3ation-100
                  transition-all
                  absolute
                  bg-white
                  c-service-bg-dark
                  top-[20px]
                  left-[-68px]
                "
                :class="{ 'show-line': selectedService == service.id }"
              ></div>
              <div
                class="
                  cursor-pointer
                  duration-100
                  transition-all
                  c-service-text
                  flex
                  items-center
                  group
                "
                @click="selectedService = service.id"
                :class="{ 'font-bold': selectedService == service.id }"
                :style="service.id == 7 ? 'color: #3863AE' : ''"
              >
                <div
                  :style="service.id == 7 ? 'background: #3863AE' : ''"
                  class="
                    w-0
                    transition-all
                    duration-300
                    h-[1px]
                    bg-white
                    mr-2
                    group-hover:w-5
                  "
                ></div>
                {{ service.title }}
              </div>
            </div>
          </div>
          <div
            class="
              col-span-12
              lg:col-span-6
              text-white
              md:px-8
              py-8
              layout-padding
              flex flex-col
              lg:justify-start
              justify-center
              xl:justify-center
              lg:pt-[200px]
              xl:pt-0
            "
          >
            <div
              class="flex cursor-pointer back-btn c-service-bg py-6 lg:hidden"
              @click="backToServices"
            >
              <img
                src="/static/images/icons/arrow-left.svg"
                class="w-[12px] arrow"
                id="arrow-left-back"
                alt="left"
              />
              <div class="title uppercase c-service-text text-xs ml-4">
                Back to Services
              </div>
            </div>
            <div class="text-[24px] xs:text-[36px] c-service-text">
              {{ selectedServiceDetails?.title }}
            </div>

            <div class="leading-7 mt-6 c-service-text">
              {{ selectedServiceDetails?.description }}
            </div>

            <router-link
              :to="`/projects?service=${selectedServiceDetails.id}`"
              v-if="selectedServiceDetails"
            >
              <div
                class="
                  uppercase
                  w-max
                  mt-8
                  relative
                  view-project-btn view-btn
                  font-bold
                  text-white
                  bg-white bg-opacity-40
                  px-[40px]
                  py-[10px]
                  c-service-text c-service-border
                "
              >
                view project
                <div
                  class="
                    view-project-btn-line
                    w-[40px]
                    border-t-2 border-white border-opacity-40
                    absolute
                    top-[21px]
                    right-[-20px]
                  "
                ></div></div
            ></router-link>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-4 relative">
        <div class="overflow-hidden hide-transition-overflow-services relative">
          <div
            class="
              w-[4px]
              h-[4px]
              bg-white
              absolute
              right-[-2px]
              z-[1]
              top-[88px]
            "
          ></div>
          <div class="services-img flex justify-start items-end">
            <div
              class="services-img absolute slide-show"
              v-if="selectedServiceDetails?.galleries?.length == 0"
              :style="`background: url(${selectedServiceDetails?.image})`"
            ></div>
            <div
              class="services-img absolute"
              v-for="(slide, index) in selectedServiceDetails?.galleries"
              :key="index"
              :style="`background: url(${slide.image})`"
              :class="currentSlide == index ? 'slide-show' : 'slide-hide'"
            ></div>
            <div
              class="overlay bg-black bg-opacity-40 absolute left-0 top-0"
            ></div>
            <!-- <div
              class="
                flex
                items-center
                w-full
                text-xs
                uppercase
                text-white
                z-0
                services-gallery
              "
            >
              <div
                class="
                  flex
                  cursor-pointer
                  prev-btn
                  bg-[#1D1D1D]
                  c-service-bg
                  px-8
                  py-6
                "
                @click="prevSlide"
              >
                <img
                  id="arrow-left-prev"
                  src="/static/images/icons/arrow-left.svg"
                  class="w-[10px] arrow"
                  alt="left"
                />
                <div class="title uppercase c-service-text">Previous</div>
              </div>
              <div
                class="
                  flex
                  cursor-pointer
                  next-btn
                  bg-[#1D1D1D]
                  c-service-bg
                  px-8
                  py-6
                "
                @click="nextSlide"
              >
                <div class="title uppercase c-service-text">Next</div>
                <img
                  id="arrow-right-next"
                  src="/static/images/icons/arrow-right.svg"
                  class="w-[10px] arrow"
                  alt="right"
                />
              </div>
         
            </div> -->
            <div
              class="absolute z-[5] h-full border-r layout-border right-[0px]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

 
<script>
export default {
  data() {
    return {
      selectedService: null,
      currentSlide: 0,
      currentService: null,
      selectedServiceDetails: null,
    };
  },
  methods: {
    backToServices() {
      this.$router.push("/services");
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide = this.currentSlide - 1;
      }
    },
    nextSlide() {
      if (
        this.currentSlide <
        this.selectedServiceDetails?.galleries?.length - 1
      ) {
        this.currentSlide = this.currentSlide + 1;
      }
    },
  },
  computed: {
    screenSize() {
      return window.screen.width;
    },
    services() {
      return this.$store.getters["services/services"];
    },
  },
  watch: {
    selectedService() {
      this.selectedServiceDetails = this.services.find(
        (service) => service.id == this.selectedService
      );
      this.$router.push({
        name: "services",
        query: {
          service: this.selectedService,
        },
      });
      if (this.selectedService == 7) {
        document.getElementById("arrow-left-back").src =
          "/static/images/icons/arrow-left-dark.svg";
        // document.getElementById("arrow-left-prev").src =
        //   "/static/images/icons/arrow-left-dark.svg";
        // document.getElementById("arrow-right-next").src =
        //   "/static/images/icons/arrow-right-dark.svg";
        document.getElementById("logo").style.backgroundImage =
          "url('/static/images/c-logo.png')";
        document.body.classList.add("custom-service");
      } else {
        document.getElementById("logo").style.backgroundImage =
          "url('/static/images/logo.png')";
        document.getElementById("arrow-left-back").src =
          "/static/images/icons/arrow-left.svg";
        // document.getElementById("arrow-left-prev").src =
        //   "/static/images/icons/arrow-left.svg";
        // document.getElementById("arrow-right-next").src =
        //   "/static/images/icons/arrow-right.svg";
        document.body.classList.remove("custom-service");
      }
    },
    services() {
      if (this.$route.query.service) {
        this.selectedServiceDetails = this.services.find(
          (service) => service.id == this.$route.query.service
        );
        this.selectedService = this.selectedServiceDetails.id;
      } else {
        this.selectedServiceDetails = this.services[0];
        this.selectedService = this.selectedServiceDetails.id;
      }
    },
    $route() {
      this.currentService = this.$route.query.service;
    },
  },
  mounted() {
    document.title = this.$route.meta.name;

    if (this.$route.query.service) {
      this.currentService = this.$route.query.service;
      this.selectedServiceDetails = this.services.find(
        (service) => service.id == this.$route.query.service
      );
      this.selectedService = this.selectedServiceDetails.id;
    } else if (this.screenSize > 1024 && !this.$route.query.service) {
      this.selectedServiceDetails = this.services[0];
      this.selectedService = this.selectedServiceDetails.id;
    }
  },
  beforeUnmount() {
    document.getElementById("arrow-left-back").src =
      "/static/images/icons/arrow-left.svg";
    // document.getElementById("arrow-left-prev").src =
    //   "/static/images/icons/arrow-left.svg";
    // document.getElementById("arrow-right-next").src =
    //   "/static/images/icons/arrow-right.svg";
  },
  unmounted() {
    document.getElementById("logo").style.backgroundImage =
      "url('/static/images/logo.png')";
    document.body.classList.remove("custom-service");
  },
};
</script>