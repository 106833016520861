import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    projects: null,
    projectDetails: null,
    filterParameters: null,
    scrollPosition: 0,
  }),
  actions,
  getters,
  mutations,
};
