import http from "../../../request";

export default {
  projects: async (context) => {
    let response = await http.get("projects", localStorage.getItem("lang"));
    context.commit("setProjects", response?.data);
  },
  projectDetails: async (context, payload) => {
    let response = await http.get(
      `projects/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setProjectDetails", response.data);
  },
  filterParameters: async (context) => {
    let response = await http.get(`parameters`, localStorage.getItem("lang"));
    context.commit("setFilterParameters", response);
  },
  filter: async (context, payload) => {
    let formData = new FormData();
    if (
      payload.category != null &&
      payload.category != "" &&
      payload.category != "all"
    ) {
      formData.append("category_id", payload.category);
    }
    if (
      payload.services != null &&
      payload.services != "" &&
      payload.services != "all"
    ) {
      formData.append("service_id", payload.services);
    }
    if (payload.year != null && payload.year != "" && payload.year != "all") {
      formData.append("year", payload.year);
    }
    if (
      payload.location != null &&
      payload.location != "" &&
      payload.location != "all"
    ) {
      formData.append("location", payload.location);
    }
    let response = await http.post(
      `filter`,
      localStorage.getItem("lang"),
      formData
    );
    context.commit("setProjects", response?.data);
  },
  setScrollPosition: async (context, payload) => {
    context.commit("setScrollPosition", payload);
  }

};
