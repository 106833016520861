export default {
  setProjects: (state, payload) => {
    state.projects = payload;
  },
  setProjectDetails: (state, payload) => {
    state.projectDetails = payload;
  },
  setFilterParameters(state, payload) {
    state.filterParameters = payload;
  },
  setScrollPosition(state, payload) {
    state.scrollPosition = payload;
  }
  
};
