<template>
  <div class="home">
    <HomeSlide :sliders="home?.sliders" />
    <HomeAbout :about="home?.texts[0]" :services="home?.services" />
    <HomeProjects :projects="home?.projects" />
    <MapSection :points="home?.details" />
  </div>
</template>

<script>
import HomeSlide from "/src/components/Home/HomeSlide.vue";
import HomeAbout from "/src/components/Home/HomeAbout.vue";
import HomeProjects from "/src/components/Home/HomeProjects.vue";
import MapSection from "/src/components/Home/MapSection.vue";

export default {
  components: {
    HomeSlide,
    HomeAbout,
    HomeProjects,
    MapSection,
  },
  computed: {
    home() {
      return this.$store.getters["home/home"];
    },
  },
  mounted() {
    document.title = this.$route.meta.name;
  },
};
</script>
