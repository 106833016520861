import { createStore } from "vuex";
import Home from "/src/store/modules/home/index.js";
import Contacts from "/src/store/modules/contacts/index.js";
import About from "/src/store/modules/about/index.js";
import Projects from "/src/store/modules/projects/index.js";
import Services from "/src/store/modules/services/index.js";
import Socials from "/src/store/modules/socials/index.js";
export default createStore({
  modules: {
    home: Home,
    contacts: Contacts,
    about: About,
    projects: Projects,
    services: Services,
    socials: Socials,
  },
  state: {
    loading: true,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async getAll(context, payload) {
      let RoutesAndActions = {
        home: "home/home",
        contacts: "contacts/contacts",
        about: "about/about",
        projects: "projects/projects",
        services: "services/services",
      };
      if (RoutesAndActions[payload] != undefined) {
        await context.dispatch(RoutesAndActions[payload]);
      }
      await context.commit("setLoading", false);
      delete RoutesAndActions[payload];
      for (let route in RoutesAndActions) {
        await context.dispatch(RoutesAndActions[route]);
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
});
