<template>
  <div
    class="
      h-[100vh]
      w-[100vw]
      z-30
      flex
      items-center
      flex-col
      justify-center
      loading bg-black
    "
  >
    <!-- <div class="container">
      <div class="box1"></div>
      <div class="box2"></div>
      <div class="box3"></div>
    </div> -->
    <img
      src="/static/images/loading-2.gif"
      alt="loading"
      class="h-[350px] w-[350px] object-contain"
    />
  </div>
</template>