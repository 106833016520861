<template>
  <div class="about">
    <div class="about-header">
      <div
        class="
          h-[70vh]
          w-full
          img
          parallex-image
          relative
          px-[3vw]
          md:px-[45px]
          lg:px-[90px]
        "
        :style="`background: url(${about.top_image})`"
      >
        <div
          class="
            overlay
            bg-black bg-opacity-40
            h-[70vh]
            w-full
            absolute
            left-0
            top-0
          "
        ></div>
        <div
          class="
            w-[4px]
            h-[4px]
            no-background-on-mobile
            bg-white
            absolute
            right-[3vw]
            md:right-[43px]
            lg:right-[88px]
            top-[75px]
            lg:top-[88px]
          "
        ></div>
        <div
          class="
            w-[4px]
            h-[4px]
            no-background-on-mobile
            bg-white
            absolute
            left-[3vw]
            md:left-[43px]
            lg:left-[88px]
            bottom-[-2px]
          "
        ></div>
        <div
          class="
            bg-[#841718]
            h-[50px]
            text-white
            tracking-widest
            z-[2]
            absolute
            right-0
            bottom-[-25px]
            flex
            md:px-5
            px-[3vw]
            items-center
            sm:w-[350px]
            w-full
          "
        >
          TOWARDS AN INNOVATIVE DESIGN
        </div>
        <div
          class="
            border-x
            layout-border
            remove-border-on-mobile
            w-full
            h-[70vh]
            relative
            z-[1]
            flex
            items-center
            md:px-5
          "
        >
          <div class="uppercase text-white text-[36px]">
            About <br />
            <strong> US</strong>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        grid grid-cols-3
        mx-[3vw]
        border-x-0
        md:mx-[px]
        lg:mx-[90px] lg:border-x
        layout-border
        remove-border-on-mobile
        min-h-[70vh]
      "
    >
      <div
        class="
          col-span-3
          lg:col-span-1 lg:pb-16
          pb-8
          text-white
          lg:px-5
          px-0
          flex flex-col
          justify-start
          lg:pt-32
          pt-32
          border-r-0
          remove-border-on-mobile
          lg:border-r
          layout-border
        "
      >
        <div class="text-[36px] font-light" v-html="about.title"></div>
        <div class="font-bold py-10 w-11/12">
          {{ about.subtitle }}
        </div>
        <div class="leading-7 w-10/12">
          {{ about.description }}
        </div>
        <a
          href="https://profile.arc-studio.co/"
          target="_blank"
          
          class="lg:flex hidden"
        >
          <div
            class="
              uppercase
              mt-12
              w-max
              relative
              view-project-btn
              font-bold
              text-white
              bg-white bg-opacity-40
              px-[40px]
              py-[10px]
            "
          >
            View Profile
            <div
              class="
                view-project-btn-line
                w-[40px]
                border-t-2 border-white border-opacity-40
                absolute
                top-[21px]
                right-[-20px]
              "
            ></div></div
        ></a>
      </div>
      <div
        class="
          col-span-3
          lg:col-span-1 lg:pb-16
          pb-8
          text-white
          lg:px-5
          px-0
          flex flex-col
          justify-start
          lg:pt-32
          pt-12
          items-center
          leading-7
          border-r-0
          lg:border-r
          remove-border-on-mobile
          layout-border
        "
      >
        <div class="w-full lg:w-11/12">
          {{ about.extra_text }}
          <a
          href="https://profile.arc-studio.co/"
            target="_blank"
            
            class="flex lg:hidden"
          >
            <div
              class="
                uppercase
                mt-12
                w-max
                relative
                view-project-btn
                font-bold
                text-white
                bg-white bg-opacity-40
                px-[40px]
                py-[10px]
              "
            >
              View Profile
              <div
                class="
                  view-project-btn-line
                  w-[40px]
                  border-t-2 border-white border-opacity-40
                  absolute
                  top-[21px]
                  right-[-20px]
                "
              ></div></div
          ></a>
        </div>
      </div>
      <div
        class="
          col-span-3
          lg:col-span-1 lg:pb-16
          pb-8
          text-white
          lg:px-5
          px-0
          flex flex-col
          justify-start
          lg:pt-32
          pt-12
          lg:items-end
          items-start
        "
      >
        <div class="relative mb-8">
          <div class="flex lg:items-end items-start">
            <div
              class="
                text-7xl text-roboto-48
                font-thin
                opacity-40
                flex
                lg:justify-end
                w-full
              "
            >
              {{ about.first_number }}
            </div>
          </div>
          <div class="text-base font-bold text-right capitalize">
            {{ about.first_title }}
          </div>
        </div>
        <div class="relative mb-8">
          <div class="flex lg:items-end items-start">
            <div
              class="
                text-7xl text-roboto-48
                font-thin
                opacity-40
                flex
                lg:justify-end
                w-full
              "
            >
              {{ about.second_number }}
            </div>
          </div>
          <div class="text-base font-bold text-right capitalize">
            {{ about.second_title }}
          </div>
        </div>
        <div class="relative mb-8">
          <div class="flex lg:items-end items-start">
            <div
              class="
                text-7xl text-roboto-48
                font-thin
                opacity-40
                flex
                lg:justify-end
                w-full
              "
            >
              {{ about.third_number }}
            </div>
          </div>
          <div class="text-base font-bold text-right capitalize">
            {{ about.third_title }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-[50vh] relative overflow-hidden">
      <div
        class="w-full h-[70vh] relative about-footer-img parallex-image2"
        :style="`background: url(${about.bottom_image})`"
      >
        <div
          class="
            overlay
            bg-black bg-opacity-40
            h-[60vh]
            w-full
            absolute
            left-0
            top-0
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  computed: {
    about() {
      return this.$store.getters["about/about"];
    },
  },
  mounted() {
    document.title = this.$route.meta.name;

    let parallexAnimation = gsap.to(".parallex-image", {
      yPercent: 10,
    });
    ScrollTrigger.create({
      trigger: ".parallex-image",
      start: "top top",
      end: "2000",
      animation: parallexAnimation,
      scrub: 1,
    });
    let parallexAnimation2 = gsap.to(".parallex-image2", {
      yPercent: -20,
    });
    ScrollTrigger.create({
      trigger: ".parallex-image2",
      start: "top bottom",
      end: "1000",
      animation: parallexAnimation2,
      scrub: 1,
    });
  },
};
</script>
