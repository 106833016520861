<template>
  <Loading v-if="loading" />
  <div v-else>
    <TheHeader />
    <router-view />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "/src/components/Layout/TheHeader.vue";
import TheFooter from "/src/components/Layout/TheFooter.vue";
import Loading from "/src/views/Loading.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    Loading,
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
  },
  mounted() {
    this.$store.dispatch("socials/socials");

    setTimeout(() => {
      this.$store.dispatch("getAll", this.$route.name);
    }, 2);
  },
};
</script>
