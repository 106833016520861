<template>
  <div>
    <div
      class="
        main-header
        c-service-border
        absolute
        left-0
        top-0
        w-full
        border-b
        layout-border
        flex
        z-[5]
      "
    >
      <div class="min-w-[90px] h-full relative z-0"></div>
      <div
        @click="$router.push('/')"
        id="logo"
        class="
          lg:h-[90px]
          h-[75px]
          lg:w-[300px]
          w-[200px]
          header-logo
          ml-2
          cursor-pointer
        "
        style="background: url(/static/images/logo.png)"
      ></div>
    </div>
    <div
      @click="menuOpened = !menuOpened"
      class="
        lg:min-w-[90px] lg:h-[90px]
        min-w-[75px]
        h-[75px]
        w-max
        cursor-pointer
        bg-[#841718]
        c-service-btn-bg
        flex
        items-center
        z-[15]
        absolute
        left-0
        top-0
        justify-center
      "
      :class="{ ' menu-icon-position': menuOpened }"
    >
      <div
        class="flex flex-col items-end justify-center cursor-pointer menu-icon"
        :class="{ 'menu-icon-open ': menuOpened }"
      >
        <div class="line bg-white w-[30px] h-[1px] flex mb-2"></div>
        <div class="line bg-white w-[25px] h-[1px] flex mb-2"></div>
        <div class="line bg-white w-[35px] h-[1px] flex"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
  <div v-if="menuOpened"
    class="
      w-[100vw]
      h-[100vh]
      fixed
      top-0
      z-[10]
      duration-300
      transition-all
      flex
    "
  > 
    <div
      class="
        bg-[#121212]
        w-[100vw]
        md:w-[50vw]
        lg:w-[30vw]
        h-[100vh]
        px-[90px]
        py-[100px]
        text-white
      "
    >
      <div class="capitalize text-[36px] pb-[8vh]">menu</div>
      <router-link
        to="/"
        @click="menuOpened = false"
        class="relative menu-item-parent flex"
      >
        <div
          class="h-[1px] w-5 left-7 top-[9px] bg-white absolute menu-line"
        ></div>
        <div class="uppercase text-sm pb-[50px] menu-item">
          Home
        </div></router-link
      >
      <router-link
        to="/about"
        @click="menuOpened = false"
        class="relative menu-item-parent flex"
      >
        <div
          class="h-[1px] w-5 left-7 top-[9px] bg-white absolute menu-line"
        ></div>
        <div class="uppercase text-sm pb-[50px] menu-item">
          About
        </div></router-link
      >
      <router-link
        to="/projects"
        @click="menuOpened = false"
        class="relative menu-item-parent flex"
      >
        <div
          class="h-[1px] w-5 left-7 top-[9px] bg-white absolute menu-line"
        ></div>
        <div class="uppercase text-sm pb-[50px] menu-item">
          Projects
        </div></router-link
      >
      <router-link
        to="/services"
        @click="menuOpened = false"
        class="relative menu-item-parent flex"
      >
        <div
          class="h-[1px] w-5 left-7 top-[9px] bg-white absolute menu-line"
        ></div>
        <div class="uppercase text-sm pb-[50px] menu-item">
          Services
        </div></router-link
      >
      <router-link
        to="/contact"
        @click="menuOpened = false"
        class="relative menu-item-parent flex"
      >
        <div
          class="h-[1px] w-5 left-7 top-[9px] bg-white absolute menu-line"
        ></div>
        <div class="uppercase text-sm pb-[50px] menu-item">
          Contact
        </div></router-link
      >
    </div>
    <div
      class="bg-black w-[0vw] md:w-[50vw] lg:w-[70vw] h-[100vh] bg-opacity-30"
      @click="menuOpened = false"
    ></div>
  </div></transition>
</template>

<script>
export default {
  data() {
    return {
      menuOpened: false,
    };
  },
};
</script>