<template>
  <teleport to="body">
    <vue-easy-lightbox :visible="visible" :moveDisabled='true' :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>
  </teleport>
  <Modal :modalOpened="modalOpened" @close="modalOpened = false">
    <div class="bg-black rounded-2xl px-4">
      <iframe width="100%" height="560" :src="`https://www.youtube.com/embed/${selectedVideo}`"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </Modal>
  <div class="
      project-details
      mx-[0vw]
      xl:mx-[90px]
      border-x-0
      md:border-x
      layout-border
      min-h-[100vh]
    ">
    <div class="
        w-[4px]
        h-[4px]
        bg-white
        absolute
        right-[3vw]
        md:right-[43px]
        lg:right-[88px]
        top-[75px]
        lg:top-[88px]
        no-background-on-mobile-xl
        z-[1]
      "></div>
    <div class="lg:pt-[90px] pt-[75px] grid grid-cols-12">
      <div class="col-span-12 xl:col-span-4 xs:col-span-4 py-[10vh] layout-padding">
        <div class="text-[30px] font-light text-[#ffffffe6]">
          {{ projectDetails?.title }}
        </div>
        <div class="mt-12 mb-16 text-[#fffc]">
          <div class="mb-2">
            Location :
            <span class="font-bold">{{ projectDetails?.location }}</span>
          </div>
          <div class="mb-2">
            Category : <span class="font-bold" v-for="category in projectDetails?.category" :key="category.id">{{
                category  
            }}</span>
          </div>
          <div class="mb-2">
            Year : <span class="font-bold">{{ projectDetails?.year }}</span>
          </div>
        </div>
        <div class="text-[#ffffffcc]">
          {{ projectDetails?.description }}
        </div>
        <div class="mt-12">
          <ReadmoreBtn title="Back to Projects" to="/projects" />
        </div>
      </div>

      <!-- <div class="col-span-12 xl:col-span-8 xs:col-span-8 relative">
        <div class="relative hide-transition-overflow-projects overflow-hidden">
          <div v-if="projectDetails?.galleries.length == 0" class="project-details-slide w-full slide-show"
            :style="`background: url(${projectDetails?.image})`" @click="showImgs(projectDetails?.image)"></div>
          <div class="project-details-slide w-full" v-for="(slide, index) in projectDetails?.galleries" :key="index"
            :style="`background: url(${slide.image})`" :class="currentSlide == index ? 'slide-show' : 'slide-hide'" @click="showImgs(slide?.image)">
            <div class="
                w-full
                h-full
                bg-black bg-opacity-30
                flex
                items-center
                justify-center
                relative
              " v-if="slide?.video" @click="playVideo(slide?.video)">
              <img src="/static/images/icons/play-btn.svg"
                class="xl:mr-[250px] md:mr-[45px] mr-[3vw] cursor-pointer h-20" alt="play" />
            </div>

          </div>
          <div class="w-full h-max z-[1] absolute bottom-0">
            <div class="w-full flex items-end justify-start h-full">
              <div class="flex items-center w-full text-xs uppercase text-white">
                <div class="flex cursor-pointer prev-btn bg-[#1D1D1D] px-8 py-6" @click="prevSlide">
                  <img src="/static/images/icons/arrow-left.svg" class="w-[10px] arrow" alt="left" />
                  <div class="title uppercase tracking-widest">Previous</div>
                </div>
                <div class="flex cursor-pointer next-btn bg-[#1D1D1D] px-8 py-6" @click="nextSlide">
                  <div class="title uppercase tracking-widest">Next</div>
                  <img src="/static/images/icons/arrow-right.svg" class="w-[10px] arrow" alt="right" />
                </div>
                <div class="
                    sm:flex
                    hidden
                    text-slideGray
                    ml-4
                    font-bold
                    text-[20px] text-roboto-14
                  ">
                  <span class="text-[42px]">{{ currentSlide + 1 }}</span> /
                  {{
                      projectDetails?.galleries.length == 0
                        ? 1
                        : projectDetails?.galleries.length
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      
      <div class="col-span-12 xl:col-span-8 xs:col-span-8 relative" :style="`min-height:${sliderHeight}px`">
       <div class=" h-max z-[1] relative top-0">
            <div class="w-full flex items-end justify-start h-full">
              <div class="flex items-center justify-between  w-full text-xs uppercase text-white">
                <div class="flex cursor-pointer prev-btn bg-[#1D1D1D] px-8 py-6" @click="prevSlide">
                  <img src="/static/images/icons/arrow-left.svg" class="w-[10px] arrow" alt="left" />
                  <div class="title uppercase tracking-widest">Previous </div>
                </div>
                 <div class="
                    sm:flex
                    hidden
                    text-slideGray
                    ml-4
                    font-bold
                    text-[20px] text-roboto-14
                  ">
                  <span class="text-[42px]">{{ currentSlide + 1 }}</span> /
                  {{
                      projectDetails?.galleries.length == 0
                        ? 1
                        : projectDetails?.galleries.length
                  }}
                </div>
                <div class="flex cursor-pointer next-btn bg-[#1D1D1D] px-8 py-6" @click="nextSlide">
                  <div class="title uppercase tracking-widest">Next</div>
                  <img src="/static/images/icons/arrow-right.svg" class="w-[10px] arrow" alt="right" />
                </div>
               
              </div>
            </div>
          </div>
        <div class="relative hide-transition-overflow-projects overflow-hidden" :style="`height:${sliderHeight}px`">
          <div v-if="projectDetails?.galleries.length == 0" class="project-details-slide w-full slide-show">
            <img :src="projectDetails?.image" id="project-slide-main-img" >
          </div>
          <div class="project-details-slide w-full" v-for="(slide, index) in projectDetails?.galleries" :key="index">
            <img :src="slide.image" :id="`project-slide-img-${index}`" class="object-contain" @click="playVideo(slide)"
              :class="currentSlide == index ? 'slide-show' : 'slide-hide'">
            <!-- <div class="
                w-full
                h-full
                bg-black bg-opacity-30
                flex
                items-center
                justify-center
                relative
              " v-if="slide?.video" @click="playVideo(slide?.video)">
              <img src="/static/images/icons/play-btn.svg"
                class="xl:mr-[250px] md:mr-[45px] mr-[3vw] cursor-pointer h-20" alt="play" />
            </div> -->

          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["id"],
  data() {
    return {
      imgs: [],
      visible: false,
      index: 0, // default: 0,
      modalOpened: false,
      selectedVideo: null,
      allImgs: [],
      sliderHeight: 0,
      currentSlide: 0,
    };
  },
  methods: {
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide = this.currentSlide - 1;
      }
    },
    showImgs(img) {
      this.imgs = img;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    nextSlide() {
      if (this.currentSlide < this.projectDetails?.galleries?.length - 1) {
        this.currentSlide = this.currentSlide + 1;
      }
    },
    playVideo(slide) {
      if(!slide.video) {
        if(this.allImgs.length==0){
          this.projectDetails?.galleries.forEach(img => {
        if(!img.video){
          this.allImgs.push(img.image);
        }
          });
        }
        this.showImgs(this.allImgs);
      }
      else{
      this.selectedVideo = slide.video;
      this.modalOpened = true;
      }
    },
  },
  computed: {
    projectDetails() {
      return this.$store.getters["projects/projectDetails"];
    },

  },
  watch: {
    projectDetails() {
      if (this.projectDetails) {

        document.title = this.projectDetails?.title;
        if (this.projectDetails?.galleries.length == 0) {
          let img = document.getElementById("project-slide-main-img");
          if (img) {
            this.sliderHeight = img.clientHeight;
          }
        }
      }
      setTimeout(() => {
        let img = document.getElementById(`project-slide-img-0`);
      if (img) {
        this.sliderHeight = img.clientHeight;
      }
      }, 700);
    },
    currentSlide() {
      if (this.projectDetails?.galleries.length > 0) {
        let img = document.getElementById(`project-slide-img-${this.currentSlide}`);
        if (img) {
          this.sliderHeight = img.clientHeight;
        }
      }
    }
  },

  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
    this.$store.dispatch("projects/projectDetails", this.id);
  },
};
</script>