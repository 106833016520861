<template>
  <div
    class="project-card relative flex items-end py-8 cursor-pointer text-white"
    @click="$router.push(`/projects/${project.id}`)"
  >

    <div
      class="
        project-card-overlay
        flex
        items-center
        justify-center
        absolute
        bottom-0
        z-[1]
        left-0
      "
    >
      <div
        class="
          circle-plus
          w-[80px]
          h-[80px]
          border border-white border-opacity-80
          rounded-full
          relative
          flex
          items-center
          justify-center
          z-[1]
        "
      >
        <div class="h-[40px] w-[1px] bg-white"></div>
        <div class="w-[40px] h-[1px] left-[20px] absolute bg-white"></div>
      </div>
    </div>
    <div
      class="project-card-img absolute bottom-0 left-0"
      :style="`background: url(${project.image})`"
    ></div>
    <div
      class="
        layout-padding
        title
        font-bold
        relative
        z-[2]
        text-left text-[20px
        ]
        w-[270px]
      "
    >
      {{ project.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
};
</script>