<template>
  <Modal :modalOpened="filterOpened" @close="filterOpened = false">
    <div
      class="
        w-full
        grid grid-cols-2
        lg:gap-x-10
        h-full
        layout-padding-2
        py-8
        rounded-2xl
        bg-[#121212]
      "
    >
      <div class="category flex flex-col mb-4 col-span-2 lg:col-span-1">
        <label class="text-white font-bold mb-2">Years </label>
        <select
          class="bg-black text-white h-[45px] py-3 rounded-sm px-4"
          v-model="filter.year"
        >
          <option :value="null" selected>All</option>
          <option :value="year" v-for="year in years" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="category flex flex-col mb-4 col-span-2 lg:col-span-1">
        <label class="text-white font-bold mb-2">Category </label>
        <select
          class="bg-black text-white h-[45px] py-3 rounded-sm px-4"
          v-model="filter.category"
        >
          <option value="all">All</option>
          <option
            :value="category.id"
            v-for="category in filterParameters?.categories"
            :key="category.id"
          >
            {{ category?.name }}
          </option>
        </select>
      </div>
      <div class="category flex flex-col mb-4 col-span-2 lg:col-span-1">
        <label class="text-white font-bold mb-2">Location </label>
        <select
          class="bg-black text-white h-[45px] py-3 rounded-sm px-4"
          v-model="filter.location"
        >
          <option value="all">All</option>
          <option value="slemani">Slemani</option>
          <option value="erbil">Erbil</option>
          <option value="dohuk">Dohuk</option>
        </select>
      </div>
      <div class="category flex flex-col mb-4 col-span-2 lg:col-span-1">
        <label class="text-white font-bold mb-2">Services </label>
        <select
          class="bg-black text-white h-[45px] py-3 rounded-sm px-4"
          v-model="filter.services"
        >
          <option value="all">All</option>
          <option
            :value="service.id"
            v-for="service in filterParameters?.services"
            :key="service.id"
          >
            {{ service?.title }}
          </option>
        </select>
      </div>
      <div class="flex">
        <div
          @click="filterProjects"
          class="
            uppercase
            cursor-pointer
            mt-6
            readmore-btn
            relative
            w-max-content
            font-bold
            text-white
            border-white border-[1px]
            lg:px-[60px]
            px-[20px]
            py-[10px]
          "
        >
          Filter
        </div>
        <div
          @click="clearFilter"
          class="
            uppercase
            cursor-pointer
            mt-6
            ml-4
            readmore-btn
            relative
            w-max-content
            font-bold
            text-white
            border-white border-[1px]
            md:px-[60px]
            px-[20px]
            py-[10px]
          "
        >
          Clear
        </div>
      </div>
    </div>
  </Modal>
  <div
    class="
      mx-[3vw]
      md:mx-[45px]
      lg:mx-[90px]
      mt-[-90px]
      pt-[90px]
      border-x-0
      md:border-x
      layout-border
      min-h-[100vh]
    "
  >
    <div
      class="
        w-[4px]
        h-[4px]
        no-background-on-mobile
        top-[75px]
        bg-white
        absolute
        right-[3vw]
        md:right-[45px]
        lg:right-[88px] lg:top-[88px]
      "
    ></div>
    <div class="mt-[90px] min-h-[20vh] grid grid-cols-3 text-white">
      <!-- lg:border-r layout-border -->
      <div class="mt-[-90px] pt-[90px] col-span-1 border-r-0">
        <div
          class="
            uppercase
            text-[24px]
            lg:text-[36px]
            md:px-3
            flex
            items-center
            h-full
          "
        >
          Projects
        </div>
      </div>
      <!-- lg:border-r layout-border -->
      <div class="mt-[-90px] pt-[90px] col-span-1 border-r-0"></div>
      <div class="mt-[-90px] pt-[90px] col-span-1 flex items-center">
        <div
          class="
            w-full
            h-[40px]
            bg-white bg-opacity-40
            flex
            items-center
            px-4
            justify-between
            cursor-pointer
          "
          @click="filterOpened = true"
        >
          <div class="uppercase font-bold text-xs lg:text-sm">Filter</div>
          <div class="uppercase font-bold">
            <span class="w-[20px] h-[2px] bg-white mb-1 flex"></span>
            <span class="w-[15px] h-[2px] bg-white mb-1 flex"></span>
            <span class="w-[10px] h-[2px] bg-white flex"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 layout-border gap-5 pb-[20vh]">
      <div class="col-span-12 lg:col-span-4">
        <!-- md:border-r layout-border -->
        <div class="text-white py-4 text-base md:px-4">
          {{ projects?.length }} results found
        </div>
      </div>
      <!-- md:border-r layout-border -->

      <div class="col-span-12 md:col-span-6 lg:col-span-4"></div>
      <div class="col-span-12 md:col-span-6 lg:col-span-4"></div>
      <div class="col-span-12">
        <div class="projects-container">
          <ProjectCard
            :project="project"
            v-for="project in projects"
            :key="project.id"
          />
        </div>
      </div>
      <div
        class="
          col-span-12
          h-full
          min-h-[100vh]
          py-8
          layout-padding
          text-white
          font-bold
          text-base
          uppercase
        "
        v-if="projects.length == 0"
      >
        No Projects Found
      </div>
    </div>
  </div>
  <div
    v-if="about"
    class="
      mx-[3vw]
      md:mx-[45px]
      lg:mx-[90px]
      pb-8
      md:border-x
      layout-border
      pt-12
      md:px-4
    "
  >
    <a href="https://profile.arc-studio.co/" target="_blank">
      <div
        class="
          uppercase
          w-max
          relative
          view-project-btn
          font-bold
          text-white
          bg-white bg-opacity-40
          px-[40px]
          py-[10px]
        "
      >
        View Digital Catalog
        <div
          class="
            view-project-btn-line
            w-[40px]
            border-t-2 border-white border-opacity-40
            absolute
            top-[21px]
            right-[-20px]
          "
        ></div>
      </div>
    </a>
  </div>
</template>

<script>
import ProjectCard from "/src/components/UI/Cards/ProjectCard.vue";
export default {
  data() {
    return {
      filterOpened: false,
      from: 2010,
      years: [],
      filter: {
        year: null,
        category: "all",
        location: "all",
        services: "all",
      },
    };
  },
  computed: {
    projects() {
      return this.$store.getters["projects/projects"];
    },
    about() {
      return this.$store.getters["about/about"];
    },
    filterParameters() {
      return this.$store.getters["projects/filterParameters"];
    },
    scrollPosition() {
      return this.$store.getters["projects/scrollPosition"];
    },
  },
  components: {
    ProjectCard,
  },
  methods: {
    async filterProjects() {
      await this.$store.dispatch("projects/filter", this.filter);

      this.filterOpened = false;
    },
    clearFilter() {
      this.filter = {
        year: null,
        category: "all",
        location: "all",
        services: "all",
      };

      this.filterProjects();
    },
  },
  async mounted() {
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 1);
    document.title = this.$route.meta.name;

    await this.$store.dispatch("projects/filterParameters");
    for (let s = 2010; s < parseInt(new Date().getFullYear()); s++) {
      this.from = this.from + 1;
      this.years.push(this.from);
    }
    setTimeout(() => {
      if (this.$route.query.service) {
        this.filter.services = this.$route.query.service;
        this.filter.year = null;
        this.filterProjects();
      }
    }, 2);
  },
  beforeUnmount() {
    this.$store.dispatch("projects/setScrollPosition", window.scrollY);
  },
};
</script>
