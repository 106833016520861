<template>
  <div v-if="modalOpened" class="modal">
    <div class="modal-bg" @click="$emit('close', false)"></div>
    <div class="p-6">
      <div class="modal-inner">
        <div
          @click="$emit('close', false)"
          class="
            w-8
            h-8
            absolute
            modal-close-btn
            flex
            item
            justify-center
            rounded-full
            bg-gray-100
            cursor-pointer
          "
        >
          <i class="fas fa-times text-gray-700 mt-2"></i>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalOpened"],
};
</script>