<template>
  <div class="h-[100vh] text-white flex items-center justify-center">
    <div class="text-center">
      <div class="text-7xl">404</div>
      <div class="text-2xl uppercase text-bold mt-5 mb-12">Page not found</div>
      <router-link to="/">
        <div
          class="
            uppercase
            readmore-btn
            relative
            w-max-content
            font-bold
            text-white
            border-white border-[1px]
            px-[60px]
            py-[10px]
          "
        >
          Back to home
        </div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = this.$route.meta.name;
  },
};
</script>