<template>
  <div class="relative bg-[#1D1D1D] overflow-hidden">
    <div
      class="
        min-h-[100vh]
        grid grid-cols-12
        mx-[3vw]
        md:mx-[45px]
        lg:mx-[90px]
        relative
      "
    >
      <div class="col-span-12 md:col-span-8 flex  min-h-[100vh]">
        <div
          class="
            min-w-0
            hidden
            sm:flex
            md:min-w-[45px]
            lg:min-w-[90px]
            h-full
            home-about-img-left
            absolute
            left-[-3vw]
            md:left-[-45px]
            lg:left-[-90px]
            border-r
            layout-border
            parallex-image
          "
          :style="`background: url(${about.image})`"
        ></div>
        <div class="grid grid-cols-2">
          <div
            class="
              lg:col-span-1
              col-span-2
              border-r-0
              md:border-r
              layout-border
              text-white
              p-5
              lg:p-10
              flex flex-col
              justify-center
            "
          >
            <div class="flex relative">
              <div
                class="
                  w-[40px]
                  border-t-2 border-white border-opacity-40
                  absolute
                  top-[26px]
                  left-[-50px]
                "
              ></div>
              <div class="text-[36px] font-light" v-html="about?.title"></div>
            </div>
            <div class="font-bold py-10">
              {{ about.subtitle }}
            </div>
            <div class="leading-7 font-light">
              {{ about.description }}
            </div>
            <ReadmoreBtn title="read more" to="/about" class="mt-12" />
          </div>
          <div
            class="
              col-span-2
              sm:col-span-1
              px-[3vw]
              border-r-0
              lg:border-r
              layout-border
              text-white
              flex flex-col
              md:p-5
              lg:p-10
              justify-center
              text-[16px]
              lg:text-[20px]
              font-bold
              lg:text-right
              text-left
              leading-10
            "
          >
            <router-link
              :to="`services?service=${service.id}`"
              v-for="service in services"
              :key="service.id"
            >
              <div
                class="
                  service-title-home
                  text-white
                  relative
                  transition-all
                  duration-300
                  ease-in-out
                  cursor-pointer
                "
                :style="service.id == 7 ? 'color: #3863AE' : ''"
              >
                {{ service.title }}
                <div
                  class="
                    h-[2px]
                    w-7
                    right-0
                    top-[20px]
                    bg-white
                    absolute
                    service-line
                  "
                  :style="service.id == 7 ? 'background: #3863AE' : ''"
                ></div></div
            ></router-link>
          </div>
          <div
            class="
              sm:flex
              md:hidden
              hidden
              col-span-1
              relative
              md:min-h-[200px]
            "
          >
            <div
              class="home-about-img parallex-image"
              :style="`background: url(${about.image})`"
            >
              <div
                class="absolute z-[5] h-full border-r layout-border right-[0px]"
              ></div>
            </div>

            <div
              class="
                w-[40px]
                h-[40px]
                rounded-full
                bg-[#841718]
                absolute
                bottom-[120px]
                left-[5px]
                lg:left-[-20px]
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="hidden md:flex md:col-span-4 relative md:min-h-[200px]">
        <div
          class="home-about-img absolute parallex-image"
          :style="`background: url(${about.image})`"
        >
          <div
            class="absolute z-[5] h-full border-r layout-border right-[0px]"
          ></div>
        </div>

        <div
          class="
            w-[40px]
            h-[40px]
            rounded-full
            bg-[#841718]
            absolute
            bottom-[120px]
            left-[-20px]
          "
        ></div>
      </div>
      <div>
        <div
          class="min-w-0 flex md:hidden w-full h-[350px] parallex-image about-home-img-mobile mt-5"
          :style="`background: url(${about.image})`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  props: ["about", "services"],
  mounted() {
    let parallexAnimation = gsap.to(".parallex-image", {
      yPercent: 20,
    });
    ScrollTrigger.create({
      trigger: ".parallex-image",
      start: "top top",
      end: "2000",
      animation: parallexAnimation,
      scrub: 1,
    });
  },
};
</script>