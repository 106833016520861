<template>
  <router-link :to="to">
    <div
      class="
        uppercase
        readmore-btn
        relative
        w-max-content
        font-bold
        text-white
        border-white border-[1px]
        px-[60px]
        py-[10px]
      "
    >
      {{ title }}
    </div></router-link
  >
</template>

<script>
export default {
  props: ["title", "to"],
};
</script>